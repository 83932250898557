import { useEffect } from "react";
import { useRouter } from "next/router";

import getContents from "@helper/util/landing/getContents";
// import { clearCacheData } from "@helper/util/useCache";

const Home = () => {
  const router = useRouter();
  const { language } = getContents();

  if (typeof window !== "undefined") {
    var check_url = window.location.origin;
  }
  
  const redirectUrl = () => {
    if(check_url === "https://dev.sellon.net") {
      fetch(check_url)
        .then(response => {
          if(response.ok === true && response.status === 200 && response.statusText === "OK") {
            router.push("/landing/");
          } else {
            // redirect to
            window.location = 'https://dev.sellon.store'
          }
        }).catch(error => {
          // console.log(error)
           // redirect to
          window.location = 'https://dev.sellon.store'
        });
    } else if(check_url === "https://dev.sellon.store") {
      fetch(check_url)
        .then(response => {
          if(response.ok === true && response.status === 200 && response.statusText === "OK") {
            router.push("/landing/");
          } else {
            // redirect to
            window.location = 'https://dev.sellon.net'
          }
        })
        .catch(error => {
          // console.log(error)
          // redirect to
          window.location = 'https://dev.sellon.net'
        });
    } else if(check_url === "https://sellon.net") {
      fetch(check_url)
        .then(response => {
          if(response.ok === true && response.status === 200 && response.statusText === "OK") {
            router.push("/landing/");
          } else {
            // redirect to
            window.location = 'https://sellon.store'
          }
        })
        .catch(error => {
          // console.log(error)
          // redirect to
          window.location = 'https://sellon.store'
        });
    } else if(check_url === "https://sellon.store") {
      fetch(check_url)
        .then(response => {
          if(response.ok === true && response.status === 200 && response.statusText === "OK") {
            router.push("/landing/");
          } else {
            // redirect to
            window.location = 'https://sellon.net'
          }
        })
        .catch(error => {
          // console.log(error)
          // redirect to
          window.location = 'https://sellon.net'
        });
    } else {
      router.push("/landing/");
      console.log(check_url)
    }
  }

  useEffect(() => {
    // router.push(`/${language}/`);
    // clearCacheData();
    // router.push("/landing/");
    redirectUrl()
  }, [language]);
  return (
    <>
      <div className="h-screen w-screen flex items-center justify-center"> Please wait, we are redirecting </div>
    </>
  );
};

export default Home;
